* {
  position: relative;
  padding: 0;
  margin: 0;
}

.bodyFooter {
  width: 100%;
}

.containerFooter {
  padding: 40px 0 20px 0;
  background-color: #1e1e1e;
}

.contentFooter {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin: 0 auto;
  font-family: "Inter";
  text-align: center;

  @media (max-width: 750px) {
    flex-direction: column;
  }

  span {
    color: #ffffff;
    margin-bottom: 10px;

    span {
      cursor: pointer;
    }
  }

  main {
    @media (max-width: 750px) {
      display: flex;
      align-items: center;
      flex-direction: column;

      div {
        display: flex;
        align-items: center;
        flex-direction: column;
      }
    }
    span {
      color: rgba(255, 255, 255, 0.4);
      font-size: clamp(12px, 1.4vw, 15px);
      text-decoration: none;
      margin-right: 0.5rem;

      a {
        text-decoration: none;
        color: rgba(255, 255, 255, 0.4);
        transition: 0.3s;

        &:hover {
          color: rgba(255, 255, 255, 1);
        }
      }

      &:nth-of-type(3) {
        font-size: clamp(12px, 1.4vw, 14px);
      }
    }

    .web {
      display: block;
      width: 100%;
      text-align: start;

      @media (max-width: 750px) {
        text-align: center;
      }
    }
  }

  .policy {
    display: flex;
    align-items: center;
    @media (max-width: 750px) {
      justify-content: center;
    }
    a {
      text-decoration: none;
    }
    span {
      opacity: 0.4;
      font-size: clamp(12px, 1.4vw, 15px);
      transition: 0.2s;

      &:hover {
        opacity: 1;
      }
    }
  }
}
