@mixin noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently*/
}

* {
  margin: 0;
  padding: 0;
  position: relative;
}

.headerLayout {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 995;
  background-color: #000000;
  box-shadow: 0 4px 2px 1px rgb(0, 0, 0);
  transition: 0.3s ease-in-out;

  &.hidden {
    background-color: transparent;
    box-shadow: none;
    .header {
      .logo,
      .title {
        opacity: 0;
      }
    }
    .nav {
      opacity: 0;
    }
  }

  &:hover {
    &.hidden {
      .header {
        .logo {
          opacity: 1 !important;
        }
      }
      .nav {
        opacity: 1 !important;
      }
    }
  }

  @media (max-width: 840px) {
    
  &.hidden {
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 995;
    background-color: #000000;
    box-shadow: 0 4px 2px 1px rgb(0, 0, 0);
    transition: 0.3s ease-in-out;
    .header {
      .logo,
      .title {
        opacity: 100;
      }
    }
    .nav {
      opacity: 0;
    }
  }
  }


}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  max-width: 100%;
  margin: 1rem 1.5rem 2rem 1.5rem;
  transition: 0.5s;

  @media (max-width: 840px) {
    margin: 1rem 0.7rem 1rem 0.7rem;
  }

  .leftContent {
    display: flex;
    align-items: center;

    .title {
      position: absolute;
      color: white;
      font-family: "Inter";
      font-weight: 700;
      font-size: clamp(12px, 2vw, 20px);
      left: clamp(3rem, 5vw, 4.5rem);
      z-index: 999;
      transition: 0.5s;

      @media (max-width: 840px) {
        display: none; 
      }
    }

    .logo {
      background-color: white;
      border-radius: 50%;
      width: clamp(35px, 4vw, 50px);
      height: clamp(35px, 4vw, 50px);
      transition: 0.5s;
      z-index: 999;

      img {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 50%;
        left: 52%;
        transform: translate(-50%, -50%);
      }
    }
  }
  .rightContent {
    display: flex;
    align-items: center;
  
    .button {
      margin-right: 1rem;
      z-index: 0;
      transition: 1s;
  
      @media (max-width: 840px) {
        // transform: translateX(-79%);
        margin-right: 60px;
        button {
          padding: 0.8rem 2rem !important;
        }
      }
  
      button {
        width: 100%;
        padding: 1.5rem clamp(1rem, 4vw, 2rem);
        background-color: #4a5dea;
        color: rgb(255, 255, 255);
        border: 1px solid #4a5dea;
        border-radius: 156px;
        cursor: pointer;
        transition: 0.3s;
        font-family: "Inter";
        font-size: clamp(13px, 1vw, 14px);
        box-shadow: 0 4px 2px 1px rgb(0, 0, 0);
  
        &:hover {
          color: black;
          background-color: #deff50;
          border: 1px solid #deff50;
        }
      }
    }
  
    .localeSelector {
      display: flex;
      flex-direction: column;
      position: relative;
      font-size: 16px;
      color: #ffffff;
      cursor: pointer;
  
      span {
        margin-bottom: 4px; // Spacing between languages
      }
  
      .localeMenu {
        position: absolute;
        top: 100%;
        left: 0;
        display: flex;
        flex-direction: column;
        opacity: 0;
        transform: translateY(-10px);
        transition: opacity 0.3s ease, transform 0.3s ease;
        visibility: hidden;
      }
  
      // When .localeSelector has the .open class, show the .localeMenu
      &.open .localeMenu {
        opacity: 1;
        transform: translateY(0);
        visibility: visible;
      }

      @media (max-width: 840px) {
        display: none; // Полностью скрываем localeSelector
      }

    }
  }
}  

.menuButton {
  position: absolute;
  right: 1%;
  width: 50px;
  height: 50px;
  z-index: -1;
  opacity: 0;

  .toggle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 25px;
    height: 3px;
    background-color: white;
    border-radius: 4px;
    transition: 0.5s;

    &:nth-child(1) {
      top: 35%;
    }

    &:nth-child(2) {
      top: 50%;
    }

    &:nth-child(3) {
      width: 15px;
      left: calc(50% - 5px);
      top: 65%;
    }
  }

  &.visible {
    opacity: 1;
    z-index: 999;
  }

  &.active {
    .toggle {
      &:nth-child(1) {
        top: 50%;
        transform: rotate(135deg);
      }

      &:nth-child(2) {
        opacity: 0;
        transform: translate(0, 0) rotate(0);
      }

      &:nth-child(3) {
        top: 50%;
        left: 50%;
        width: 25px;
        transform: rotate(-135deg);
      }
    }
  }
}

.nav {
  position: absolute;
  top: calc(50% - 0.5rem);
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  background-color: #1e1e1e;
  box-shadow: 0 4px 7px 1px rgb(0, 0, 0);
  border-radius: 156px;
  font-family: "Inter";
  transition: 0.5s;
  overflow: hidden;

  .button {

    display: none;}

  &.mobile {
    width: 100%;
    height: 0;
    border-radius: 0 0 15px 15px;
    top: 23.5%;
    display: flex;
    justify-content: center;
    box-shadow: none;
    transition: 1s;


  
    ul {
      width: 50%;
      // margin-top: -30px !important;
      flex-direction: column;
      align-items: center;
      opacity: 0;
      height: max-content;
        
    .button {
      // margin-right: 1rem;
      // z-index: 0;
      // transition: 1s;
      display: none;
  
      @media (max-width: 840px) {
        display: inline;
        margin: 15px 0 15px 0;
        
          button {
            width: 100%;
            // padding: 1.5rem clamp(1rem, 4vw, 2rem);
            background-color: #4a5dea;
            color: rgb(255, 255, 255);
            border: 1px solid #4a5dea;
            border-radius: 156px;
            cursor: pointer;
            transition: 0.3s;
            font-family: "Inter";
            font-size: clamp(13px, 1vw, 14px);
            box-shadow: 0 4px 2px 1px rgb(0, 0, 0);
            padding: 0.8rem 2rem !important;
          }
       
        
      }
  
      
    }
    
      

      li {
        width: 100%;
      }

      .underline {
        width: 65%;
        height: 20%;
      }
    }

    &.active {
      height: 60rem;
      box-shadow: 0 4px 7px 1px rgb(0, 0, 0);

      ul {
        margin-top: 33rem !important;
        opacity: 1;
      }
    }
  }

  ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    transition: 1s;

    li {
      position: relative;
      width: 25%;
      text-align: center;
      @include noselect();

      a {
        display: flex;
        color: rgb(255, 255, 255);
        z-index: 8;
        padding: 1.5rem 0;
                font-size: clamp(18px, 1vw, 18px);
        text-decoration: none;
        justify-content: center;
        text-align: center;
        border-radius: 25px;
        cursor: pointer;
        mix-blend-mode: difference;
      }
    }


    .localeMenuisOpen{
      display: none; // Полностью скрываем localeSelector
      @media (max-width: 840px) {
        display: block;
      color: #ffffff;
      margin-top: 30px;
      // padding: 5px;

      span{
        padding: 5px;
      }
    }
    }
  }

  .underline {
    position: absolute;
    top: 50%;
    z-index: 2;
    transform: translate(0, -50%);
    width: 25%;
    height: 100%;
    background-color: white;
    transition: 0.35s;
    border-radius: 156px;

    @media (max-width: 840px) {
      background-color: rgba(0, 0, 0, 0);
    }

  }



}

