@font-face {
  font-family: "MersadExtraLight";
  src: url("./components/Mersad-ExtraLight.ttf");
  font-weight: 200;
  font-style: normal;
}

* {
  margin: 0;
  padding: 0;
  position: relative;
}

.layout {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  z-index: 10;
  margin: 0 auto;
  background-color: #010101;
}

.section {
  position: relative;
  z-index: 10;
  background-color: transparent;
  max-width: 2200px;
  margin: 0 0 0 0;
  color: white;
  overflow: hidden;

  @media (max-width: 560px) {
    margin-top: 0;
  }
}

.headerBlock {
  display: flex;
  width: 95%;
  margin: 4rem auto 0 auto;

  @media (max-width: 1430px) {
    .description {
      .description {
        width: 100% !important;
      }
    }
  }

  @media (max-width: 560px) {
    height: 60vh;
    flex-direction: column;

    .description {
      width: 80% !important;
      height: 20vh;

      @media (max-width: 355px) {
        width: 80% !important;
      }

      .description {
        width: 100% !important;
        margin-top: 1rem !important;
      }
    }

    .visual {
      position: absolute;

      .addCard1 {
        border-radius: 11px !important;
        width: 85% !important;
        height: 5rem !important;
        top: 13rem !important;
        transform: translateY(-50%);
        left: 77%;

        .description {
          padding: 0.2rem 1rem 0.5rem 0.5rem !important;
        }

        img {
          border-radius: 11px !important;
        }
      }

      .addCard2 {
        border-radius: 11px !important;
        width: 75% !important;
        height: 5rem !important;
        left: 0;
        top: 13rem !important;
        transform: translateY(-50%);
        right: 0 !important;

        .description {
          text-align: start !important;
        }

        .lower {
          label {
            font-size: clamp(18px, 4vw, 28px) !important;
          }
        }

        .image {
          border-radius: 8px !important;
          @media (max-width: 768px) {
            display: none;
          }

        }
      }

      .addCard3 {
        bottom: 0 !important;
        width: 90% !important;
        height: 5rem !important;
        top: 18.4rem !important;
        transform: translateY(-50%);
        border-radius: 11px !important;

        
        .description {
          width: 8rem !important;
          padding: 0.5rem 0 0 0.5rem !important;
          font-size: clamp(15.5px, 1.3vw, 20px) !important;

          @media (max-width: 370px) {
            
            width: 7rem !important;
            font-size: clamp(14px, 1.3vw, 20px) !important;
          }
        
      }

      .number {
        position: absolute;
        right: 0;
        bottom: 0;
        
        @media (max-width: 768px) {
          display: none !important;
        }
      }
      }

      .mercury {
        position: relative !important;
        transform: translate(0, 4%) !important;
        scale: 1.4 !important;
      }
    }
  }

  .description {
    width: 50%;
  
    .HText {
      display: block;
      font-size: clamp(40px, 5vw, 72px);
      font-family: "Inter";
      text-wrap: nowrap;
    }
  
    .descriptionText {
      display: block;
      font-size: clamp(18px, 3vw, 30px);
      font-family: "Inter";
      opacity: 0.75;
      width: 95%;
      margin-top: 10px;
      // clamp(2rem, 5vw, 4rem);
    }
  
    .descriptionButton {
      display: flex;
      margin-top: 35vh;
      z-index: 50;
  
      .button {
        background-color: #4a5dea;
        min-width: fit-content;
        font-size: clamp(16px, 1.7vw, 19px);
        padding: clamp(0.8rem, 2vw, 1rem) 2rem;
        border-radius: 60px;
        text-align: center;
        transition: 0.2s;
        cursor: pointer;
  
        &:hover {
          color: black;
          background-color: #deff50;
        }
      }
  
      .state {
        display: flex;
        align-items: center; 
        justify-content: flex-start;
        margin-left: 1rem;
        font-size: clamp(13px, 2vw, 19px);
        // padding: 0.5rem 0;
  
        @media (max-width: 768px) {
          margin-left: 0rem;
        }
  
        img {
          width: clamp(50px, 2vw, 70px);
          filter: sepia(100%) saturate(0%) hue-rotate(255deg) brightness(150%)
            contrast(200%);
          // margin-right: 1px; /* Set margin for spacing between img and text */
        }
  
        span {
          font-size: inherit;
          margin-left: -8px;
        }
      }
    }
  }
  

  .visual {
    width: 50%;

    .addCard1 {
      position: absolute;
      top: 0;
      display: flex;
      border-radius: 20px 20px 0px 20px;
      background-color: rgba(255, 255, 255, 0.4);
      backdrop-filter: blur(10px);
      font-family: "Inter";
      width: 40%;
      height: 25%;

      .description {
        width: 45%;
        padding: 0.5rem 1rem 0.5rem 1rem;
        font-size: clamp(14.5px, 1.7vw, 22px);
        z-index: 8;
      }

      img {
        position: absolute;
        border-radius: 20px 20px 0px 20px;
        width: 100%;
        height: 100%;
        z-index: 6;
      }
    }

    .addCard2 {
      position: absolute;
      top: 0;
      right: 6%;
      border-radius: 20px 20px 20px 0px;
      background-color: #deff50;
      backdrop-filter: blur(10px);
      font-family: "Inter";
      width: 35%;
      height: 25%;

      .description {
        position: relative;
        display: flex;
        justify-content: start;
        width: 90%;
        height: 20%;
        padding-top: 0.5rem;
        padding-left: 0.5rem;
        color: black;
        font-weight: 500;

        @media (max-width: 560px) {
          padding-top: 0.2rem;
        }

        span {
          font-size: clamp(16px, 1.7vw, 22px);

          @media (max-width: 560px) {
            font-size: clamp(17px, 1.3vw, 20px);
          }

          @media (max-width: 345px) {
            font-size: clamp(15px, 1.3vw, 20px) !important;
          }
        }
      }

      .lower {
        position: relative;
        height: 65%;
        display: flex;
        padding: 0 0.5rem;

        @media (max-width: 560px) {
          justify-content: space-between;
        }

        label {
          width: 70%;
          margin-top: auto;
          color: black;
          font-size: clamp(16px, 2vw, 28px);
          font-weight: 500;
          padding-left: 0.2rem;
        }

        div {
          width: 20%;
          height: 100%;
        }

        .image {
          width: clamp(29px, 4vw, 60px);
          height: clamp(29px, 4vw, 60px);
          background-color: black;
          border-radius: 15px;
          margin-top: auto;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          scale: 2;
          filter: sepia(40%) saturate(2501%) hue-rotate(16deg) brightness(103%)
            contrast(109%);
        }
      }

      .number {
        width: 40%;
        font-size: 80px;
        font-style: italic;
        font-weight: bold;
        opacity: 0.8;
        letter-spacing: -6px;
        margin-top: auto;
      }
    }

    .addCard3 {
      position: absolute;
      bottom: 10%;
      display: flex;
      border-radius: 20px 0px 20px 20px;
      background-color: rgba(255, 255, 255, 0.4);
      backdrop-filter: blur(10px);
      font-family: "Inter";
      width: 50%;
      height: 27%;

      .description {
        width: 10rem;
        padding: 1rem;
        font-size: clamp(14px, 1.7vw, 22px);
      }

      .number {
        display: flex;
        position: absolute;
        bottom: 0;
        right: 0;
        width: 40%;
        justify-content: end;
        font-size: clamp(35px, 4vw, 80px);
        font-style: italic;
        font-weight: bold;
        opacity: 0.8;
        padding-right: 0.5rem;
        letter-spacing: clamp(0px, 1.3vw, -6px);
        margin-top: auto;
      }
    }

    .mercury {
      position: absolute;
      top: 95%;
      left: 70%;
      height: 100%;
      transform: translate(-50%, -50%);
      scale: 1.7;
      z-index: -1;
      opacity: 0.35;

      @media (width > 1950px) {
        left: 65%;
      }
    }
  }
}

.caseBlock {
  width: 95%;
  margin: 7rem auto 0 auto;

  @media (max-width: 700px) {
    margin: 0 auto;
  }

  @media (max-width: 960px) {
    height: 100%;
  }

  .Row {
    font-size: 41px;
    font-family: "Inter";

    @media (max-width: 960px) {
      text-align: center;
    }
  }

  .caseCards {
    position: relative;
    width: 100%;
    height: 50rem;
    display: flex;
    margin-top: 4rem;
    justify-content: center;
    overflow: hidden;

    @media (max-width: 960px) {
      height: clamp(79rem, 150vw, 85rem);
    }

    @media (max-width: 630px) {
      height: clamp(65rem, 185vw, 79rem);
    }

    @media (max-width: 500px) {
      height: clamp(58rem, 215vw, 62rem);
    }

    @media (max-width: 350px) {
      height: clamp(56rem, 215vw, 59rem);
    }

    .upperRightCard {
      position: absolute;
      display: block;
      right: 0;
      width: 49.75%;
      height: 42%;
      background-color: #4d60e2;
      border-radius: 35px;
      overflow: hidden;
      transition: 1s;
      cursor: pointer;

      &:hover {
        .lower {
          bottom: -25%;
        }
      }

      @media (max-width: 960px) {
        width: 100%;
        height: 24%;
        top: 25%;
      }

      &.active {
        width: 100%;
        height: 100%;

        @media (max-width: 960px) {
          top: 0%;
        }

        & .header {
          bottom: 75%;
          right: 65%;
          width: 28%;

          @media (max-width: 1400px) {
            bottom: 70%;
          }

          @media (max-width: 960px) {
            bottom: 0;
            width: 45%;
            right: none;
            left: 0;
            top: 3%;
          }

          @media (max-width: 340px) {
            left: -5%;

            label {
              font-size: 1.5rem !important;
            }
          }

          label {
            display: block;
            font-size: clamp(30px, 4vw, 45px);
          }
        }

        & .rowList {
          &.active {
            .description {
              opacity: 1 !important;
              transform: translateY(0) !important;
            }

            .header {
              opacity: 1 !important;
              transform: translateY(0) !important;
            }
          }
        }

        & .rightSide {
          opacity: 1;
        }

        & .lower {
          bottom: 0;
          left: 0;
          margin: 0 0 1rem 2rem;

          @media (max-width: 960px) {
            top: 0%;
            left: 80%;
          }

          @media (max-width: 800px) {
            left: 75%;
          }

          @media (max-width: 660px) {
            left: 70%;
          }

          @media (max-width: 540px) {
            left: 65%;
          }

          @media (max-width: 465px) {
            left: 60%;
          }

          span {
            font-size: 100px;
            font-weight: 500;
            color: #defc65;

            @media (max-width: 550px) {
              font-size: 80px;
            }
          }
        }

        & .cards,
        .rightSide,
        .image {
          opacity: 1 !important;
        }

        & .backBut {
          bottom: 5%;

          .image {
            z-index: 50;
            cursor: pointer;
          }
        }
      }

      &.hidden {
        transition: 0.5s;
        transform: translateY(-110%);
       
        @media (max-width: 960px) {
          transform: translate(-105%, 0);
        }
      }

      .header {
        position: absolute;
        bottom: 0;
        right: 0;
        display: block;
        width: 33%;
        margin: 0 3rem 3rem 0;
        font-family: "Inter";
        transition: 1s;

        @media (max-width: 1100px) {
          width: 43%;
          margin: 0 0 3rem 2rem;
        }

        @media (max-width: 960px) {
          right: auto;
          margin: 0 0 3rem 1rem;
        }

        @media (max-width: 450px) {
          width: 25%;
        }

        label {
          display: block;
          font-size: clamp(15px, 2.5vw, 22px);

          @media (max-width: 960px) {
            font-size: clamp(15px, 3vw, 22px);
          }
        }
      }

      .lower {
        position: absolute;
        display: flex;
        width: 98%;
        transition: 1s;
        left: 5%;
        bottom: -40%;

        @media (max-width: 1130px) {
          bottom: -30%;
        }

        @media (max-width: 960px) {
          left: 55%;
        }

        @media (max-width: 580px) {
          bottom: -35%;
          left: 48%;
        }

        @media (max-width: 450px) {
          left: 36%;
        }

        span {
          font-size: clamp(156px, 30vw, 287px);
          font-weight: 300;
          color: #defc65;

          @media (max-width: 1130px) {
            font-size: clamp(156px, 22vw, 287px);
          }

          @media (max-width: 960px) {
            font-size: 240px;
          }

          @media (max-width: 500px) {
            font-size: 180px;
          }
        }
      }

      .contentBox {
        display: flex;
        .leftSide {
          width: 50%;

          @media (max-width: 960px) {
            position: absolute;
            width: 100%;
            height: 70%;
          }

          .image {
            width: 100%;
            height: 100%;
            transition: 1s;
            opacity: 0;
            img {
              position: absolute;
              top: 0;
              left: clamp(10rem, 25vw, 26rem);
              transform: translate(-50%, -10%);
              transition: 0.5s;

              @media (max-width: 768px) {
                width: 120%;
                height: 120%;
                object-fit: contain;
                transform: translate(-30%, -25%);
              }

              // @media (max-width: 560px) {
              //   transform: translate(-35%, -25%);
              // }

              // @media (max-width: 490px) {
              //   transform: translate(-40%, -25%);
              // }

              // @media (max-width: 420px) {
              //   transform: translate(-45%, -25%);
              // }

              // @media (max-width: 375px) {
              //   transform: translate(-55%, -25%);
              // }
            }
          }
        }

        .rightSide {
          width: 50%;
          height: 100%;
          transition: 1s;
          opacity: 0;

          @media (max-width: 960px) {
            width: 95%;
            margin: 75% auto 0 auto;
          }

          @media (max-width: 760px) {
            margin: 85% auto 0 auto;
          }

          .rowList {
            width: 95%;
            gap: 1rem;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-top: 2.5rem;

            @media (max-width: 960px) {
              width: 100%;
            }

            .row {
              display: flex;
              justify-content: space-between;
              overflow: hidden;
              border-top: 1px solid white;

              .header {
                position: relative;
                bottom: 0;
                right: 0;
                width: auto;
                margin: 1rem 0 0 1rem;
                color: rgba(255, 255, 255, 0.5);
                font-size: clamp(16px, 4vw, 25px);
                transition: 1s;
                transform: translateY(-150%);
                opacity: 0;
              }

              .description {
                width: 65%;
                font-size: clamp(13px, 2vw, 25px);
                margin: 1rem 0 0 1rem;
                transition: 1s;
                transform: translateY(-150%);
                opacity: 0;
                font-family: "Inter";
              }
            }
          }

          .cards {
            width: 96%;
            display: flex;
            flex-wrap: wrap;
            gap: 0.2rem;
            margin-top: 2rem;

            @media (max-width: 960px) {
              width: 100%;
            }

            .card {
              display: flex;
              flex-direction: column;
              height: max-content;
              gap: 2rem;
              width: calc(33.333% - 0.2rem);
              background-color: #687bff;
              border-radius: 14px;

              &:nth-child(1) {
                animation: fly-down 4s ease-in-out infinite;
              }

              &:nth-child(2) {
                animation: fly-up 4s ease-in-out infinite;
              }

              &:nth-child(3) {
                animation: fly-up2 4s ease-in-out infinite;
              }

              &:nth-child(4) {
                animation: fly-up2 4s ease-in-out infinite;

                @media (max-width: 350px) {
                  animation: fly-up3 4s ease-in-out infinite;
                }
              }

              &:nth-child(5) {
                animation: fly-down2 4s ease-in-out infinite;
              }

              .HText {
                padding: 1rem 1rem 0 1rem;
                color: rgba(255, 255, 255, 0.5);
                font-size: clamp(13px, 3.5vw, 22px);
              }

              .value {
                font-size: clamp(30px, 5vw, 48px);
                display: flex;
                justify-content: end;
                padding: 0 1rem 1rem 0;

                @media (max-width: 320px) {
                  padding: 0 0.5rem 1rem 0;
                }
              }
            }
          }
        }
      }

      .backBut {
        position: absolute;
        display: flex;
        width: 98%;
        margin: 0 auto;
        justify-content: end;
        transition: 1s;
        bottom: -30%;

        @media (max-width: 700px) {
          width: 95%;
        }

        span {
          margin: auto 0;
          margin-right: 1rem;
        }

        .image {
          width: 55px;
          height: 55px;
          border-radius: 50%;
          background-color: rgba(255, 255, 255, 1);
        }

        img {
          position: absolute;
          top: 35%;
          left: 28%;
          scale: 0.5;
          transform: translate(-50%, -50%);
          filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(255deg)
            brightness(100%) contrast(104%);
        }
      }
    }

    .upperLeftCard {
      position: absolute;
      display: block;
      left: 0;
      width: 49.75%;
      height: 42%;
      background-color: #4d60e2;
      border-radius: 35px;
      overflow: hidden;
      transition: 1s;
      cursor: pointer;

      &:hover {
        .lower {
          bottom: -25%;
        }
      }

      @media (max-width: 960px) {
        width: 100%;
        height: 24%;
      }

      &.active {
        width: 100%;
        height: 80%;

        & .header {
          bottom: 73%;
          // right: 70%;
          left: 3%;
          width: 33rem;
          // margin: 0 0 0 0;
          @media (max-width: 1295px) {
            width: 40%;
            margin: 0 0 3rem 0;
          }

          @media (max-width: 960px) {
            width: 40%;
            right: 45%;
            bottom: 0;
            top: 3%;
            left: 3%;
          }

          @media (max-width: 715px) {
            width: 43%;
          }

          @media (max-width: 670px) {
            width: 18rem;
          }

          @media (max-width: 500px) {
            width: 15rem;
            right: 25%;
          }

          @media (max-width: 360px) {
            width: 70%;
          }

          label {
            display: block;
            font-size: clamp(25px, 3.4vw, 45px);

            @media (max-width: 500px) {
              font-size: clamp(22px, 3vw, 45px);
            }
          }
        }

        & .rowList {
          &.active {
            .description {
              opacity: 1 !important;
              transform: translateY(0) !important;
            }

            .header {
              opacity: 1 !important;
              transform: translateY(0) !important;
            }
          }
        }

        & .rightSide {
          opacity: 1;
        }

        & .lower {
          bottom: 0;
          left: 0;
          margin: 0 0 1rem 2rem;

          @media (max-width: 960px) {
            top: 0%;
            left: 80%;
          }

          @media (max-width: 800px) {
            left: 75%;
          }

          @media (max-width: 660px) {
            left: 70%;
          }

          @media (max-width: 540px) {
            left: 65%;
          }

          span {
            font-size: 100px;
            font-weight: 500;
            color: #defc65;

            @media (max-width: 550px) {
              font-size: 80px;
            }
          }
        }

        & .cards,
        .rightSide,
        .image {
          opacity: 1 !important;
        }

        & .backBut {
          bottom: 5%;

          .image {
            z-index: 50;
            cursor: pointer;
          }
        }
      }

      &.hidden {
        transition: 0.5s;
        transform: translateY(-110%);

        @media (max-width: 960px) {
          transform: translate(-105%, 0);
        }
      }

      .header {
        position: absolute;
        bottom: 0;
        right: 0;
        display: block;
        width: 36%;
        margin: 0 3rem 3rem 0;
        font-family: "Inter";
        transition: 1s;

        @media (max-width: 960px) {
          left: 50%;
        }

        @media (max-width: 1100px) {
          width: 43%;
          margin: 0 0 3rem 2rem;
        }

        @media (max-width: 450px) {
          width: 11rem;
          margin: 0 0 3rem 0.5rem;
        }

        @media (max-width: 420px) {
          width: 11rem;
          margin: 0 0 3rem 0.5rem;
        }

        @media (max-width: 365px) {
          width: 12rem;
          margin: 0 0 2.5rem 1.5rem;
        }

        label {
          display: block;
          font-size: clamp(15px, 2.5vw, 22px);

          @media (max-width: 960px) {
            font-size: clamp(15px, 3vw, 22px);
          }

          @media (max-width: 365px) {
            font-size: clamp(13px, 3vw, 22px);
          }
        }
      }

      .lower {
        position: absolute;
        display: flex;
        width: 98%;
        transition: 1s;
        left: 5%;
        bottom: -40%;

        @media (max-width: 1130px) {
          bottom: -35%;
        }

        @media (max-width: 960px) {
          left: 3%;
        }

        @media (max-width: 560px) {
          bottom: -38%;
        }

        @media (max-width: 380px) {
          left: 2%;
        }

        @media (max-width: 345px) {
          left: 0;
        }

        span {
          font-size: clamp(156px, 30vw, 287px);
          font-weight: 300;
          color: #defc65;

          @media (max-width: 1130px) {
            font-size: clamp(156px, 22vw, 287px);
          }

          @media (max-width: 960px) {
            font-size: 240px;
          }

          @media (max-width: 500px) {
            font-size: 180px;
          }
        }
      }

      .contentBox {
        display: flex;
        .leftSide {
          width: 50%;

          @media (max-width: 960px) {
            position: absolute;
            width: 100%;
            height: 70%;
          }

          .image {
            width: 100%;
            height: 100%;
            transition: 1s;
            opacity: 0;
            img {
              position: absolute;
              top: 0;
              left: clamp(10rem, 25vw, 26rem);
              transform: translate(-55%, 0);
              transition: 0.5s;
              filter: drop-shadow(0 4px 30px rgba(0, 0, 0, 0.5));

              @media (max-width: 960px) {
                width: 100%;
                height: 100%;
                object-fit: contain;
                transform: translate(-30%, 5%);
              }

              @media (max-width: 560px) {
                transform: translate(-35%, 5%);
              }

              @media (max-width: 490px) {
                transform: translate(-40%, 5%);
              }

              @media (max-width: 400px) {
                transform: translate(-45%, 0);
              }

              @media (max-width: 350px) {
                transform: translate(-50%, -3%);
              }
            }
          }
        }

        .rightSide {
          width: 50%;
          height: 100%;
          transition: 1s;
          opacity: 0;

          @media (max-width: 960px) {
            width: 95%;
            margin: 75% auto 0 auto;
          }

          @media (max-width: 760px) {
            margin: 85% auto 0 auto;
          }

          .rowList {
            width: 95%;
            gap: 2rem;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-top: 2.5rem;

            @media (max-width: 960px) {
              width: 100%;
              height: max-content;
              gap: 2rem;
            }

            .row {
              display: flex;
              justify-content: space-between;
              overflow: hidden;
              border-top: 1px solid white;

              .header {
                position: relative;
                left: 0 !important;
                bottom: 0;
                right: 0;
                width: auto;
                margin: 1rem 0 0 1rem;
                color: rgba(255, 255, 255, 0.5);
                font-size: clamp(16px, 4vw, 25px);
                transition: 1s;
                transform: translateY(-150%);
                opacity: 0;
              }

              .description {
                width: 65%;
                font-size: clamp(13px, 2vw, 25px);
                margin: 1rem 0 0 1rem;
                transition: 1s;
                transform: translateY(-150%);
                opacity: 0;
                font-family: "Inter";
              }
            }
          }

          .cards {
            width: 96%;
            display: flex;
            flex-wrap: wrap;
            gap: 0.2rem;
            margin-top: 2rem;

            @media (max-width: 960px) {
              width: 100%;
            }

            .card {
              display: flex;
              flex-direction: column;
              height: max-content;
              gap: 2rem;
              width: calc(33.333% - 0.2rem);
              background-color: #687bff;
              border-radius: 14px;

              &:nth-child(1) {
                animation: fly-down 4s ease-in-out infinite;
              }

              &:nth-child(2) {
                animation: fly-up 4s ease-in-out infinite;
              }

              &:nth-child(3) {
                animation: fly-up2 4s ease-in-out infinite;
              }

              &:nth-child(4) {
                animation: fly-up2 4s ease-in-out infinite;
              }

              &:nth-child(5) {
                animation: fly-down2 4s ease-in-out infinite;
              }

              .HText {
                padding: 1rem 0 0 1rem;
                color: rgba(255, 255, 255, 0.5);
                font-size: 22px;
              }

              .value {
                font-size: clamp(30px, 5vw, 48px);
                display: flex;
                justify-content: end;
                padding: 0 1rem 1rem 0;
              }
            }
          }
        }
      }

      .backBut {
        position: absolute;
        display: flex;
        width: 98%;
        margin: 0 auto;
        justify-content: end;
        transition: 1s;
        bottom: -30%;

        @media (max-width: 700px) {
          width: 95%;
        }

        span {
          margin: auto 0;
          margin-right: 1rem;
        }

        .image {
          width: 55px;
          height: 55px;
          border-radius: 50%;
          background-color: rgba(255, 255, 255, 1);
        }

        img {
          position: absolute;
          top: 35%;
          left: 28%;
          scale: 0.5;
          transform: translate(-50%, -50%);
          filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(255deg)
            brightness(100%) contrast(104%);
        }
      }
    }

    .downRightCard {
      position: absolute;
      display: block;
      bottom: 0;
      right: 0;
      width: 49.75%;
      height: 42%;
      background-color: #4d60e2;
      border-radius: 35px;
      overflow: hidden;
      transition: 1s;
      margin-bottom: 7.5rem;
      cursor: pointer;

      &:hover {
        .lower {
          bottom: -25%;
        }
      }

      @media (max-width: 960px) {
        width: 100%;
        height: 25%;
        margin-bottom: 0;
      }

      &.active {
        width: 100%;
        height: 100%;
        margin-bottom: 0;

        & .header {
          bottom: 76%;
          right: none;
          left: 5%;
          width: 35%;
          margin: 0 0 3rem 0;

          @media (max-width: 960px) {
            bottom: 0;
            left: 2rem;
            top: 3%;
          }

          @media (max-width: 400px) {
            label {
              font-size: 1.5rem !important;
            }
          }

          label {
            display: block;
            font-size: clamp(30px, 3.6vw, 45px);
          }
        }

        & .rowList {
          &.active {
            .description {
              opacity: 1 !important;
              transform: translateY(0) !important;
            }

            .header {
              opacity: 1 !important;
              transform: translateY(0) !important;
            }
          }
        }

        & .rightSide {
          opacity: 1;
        }

        & .lower {
          bottom: 0;
          left: 0;
          margin: 0 0 1rem 2rem;

          @media (max-width: 960px) {
            top: 0%;
            left: 80%;
          }

          @media (max-width: 800px) {
            left: 75%;
          }

          @media (max-width: 660px) {
            left: 70%;
          }

          @media (max-width: 540px) {
            left: 65%;
          }

          @media (max-width: 465px) {
            left: 60%;
          }

          span {
            font-size: 100px;
            font-weight: 500;
            color: #defc65;

            @media (max-width: 550px) {
              font-size: 80px;
            }
          }
        }

        & .cards,
        .rightSide,
        .image {
          opacity: 1 !important;
        }

        & .backBut {
          bottom: 3%;

          .image {
            z-index: 50;
            cursor: pointer;
          }
        }
      }

      &.hidden {
        transition: 0.5s;
        transform: translateY(150%);

        @media (max-width: 960px) {
          transform: translateY(150%, 0);
        }
      }

      .header {
        position: absolute;
        bottom: 0;
        left: 5%;
        display: block;
        width: 33%;
        margin: 0 3rem 3rem 0;
        font-family: "Inter";
        transition: 1s;

        @media (max-width: 1100px) {
          margin: 0 0 3rem 2rem;
        }

        @media (max-width: 960px) {
          right: auto;
        }

        @media (max-width: 450px) {
          width: 36%;
          margin: 0 0 3rem 0;
        }

        label {
          display: block;
          font-size: clamp(15px, 2.5vw, 22px);

          @media (max-width: 960px) {
            font-size: clamp(15px, 3vw, 22px);
          }
        }
      }

      .lower {
        position: absolute;
        display: flex;
        transition: 1s;
        right: 0;
        bottom: -42%;

        @media (max-width: 1130px) {
          bottom: -32%;
        }

        @media (max-width: 960px) {
          left: 55%;
        }

        @media (max-width: 580px) {
          bottom: -35%;
          left: 45%;
        }

        @media (max-width: 450px) {
          left: 38%;
        }

        span {
          font-size: clamp(156px, 30vw, 287px);
          font-weight: 300;
          color: #defc65;

          @media (max-width: 1130px) {
            font-size: clamp(156px, 22vw, 287px);
          }

          @media (max-width: 960px) {
            font-size: 240px;
          }

          @media (max-width: 500px) {
            font-size: 180px;
          }
        }
      }

      .contentBox {
        display: flex;
        .leftSide {
          width: 50%;

          @media (max-width: 960px) {
            position: absolute;
            width: 100%;
            height: 70%;
          }

          .image {
            width: 100%;
            height: 100%;
            transition: 1s;
            opacity: 0;
            img {
              position: absolute;
              top: 0;
              left: clamp(10rem, 25vw, 26rem);
              transform: translate(-50%, 20%);
              transition: 0.5s;
              filter: drop-shadow(0 4px 30px rgba(0, 0, 0, 0.5));

              @media (max-width: 960px) {
                width: 100%;
                height: 100%;
                object-fit: contain;
                transform: translate(-20%, -10%);
              }

              @media (max-width: 560px) {
                transform: translate(-23%, -10%);
              }

              @media (max-width: 490px) {
                transform: translate(-30%, -10%);
              }

              @media (max-width: 420px) {
                transform: translate(-35%, -10%);
              }

              @media (max-width: 375px) {
                transform: translate(-38%, -15%);
              }
            }
          }
        }

        .rightSide {
          width: 50%;
          height: 100%;
          transition: 1s;
          opacity: 0;

          @media (max-width: 960px) {
            width: 95%;
            margin: 75% auto 0 auto;
          }

          @media (max-width: 760px) {
            margin: 85% auto 0 auto;
          }

          @media (max-width: 580px) {
            margin: 100% auto 0 auto;
          }

          .rowList {
            width: 95%;
            gap: 1rem;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-top: 2.5rem;

            @media (max-width: 960px) {
              width: 100%;
            }

            .row {
              display: flex;
              justify-content: space-between;
              overflow: hidden;
              border-top: 1px solid white;

              .header {
                position: relative;
                bottom: 0;
                left: 0 !important;
                right: 0;
                width: auto;
                margin: 1rem 0 0 1rem;
                color: rgba(255, 255, 255, 0.5);
                font-size: clamp(16px, 4vw, 25px);
                transition: 1s;
                transform: translateY(-150%);
                opacity: 0;
              }

              .description {
                width: 65%;
                font-size: clamp(13px, 2vw, 25px);
                margin: 1rem 0 0 1rem;
                transition: 1s;
                transform: translateY(-150%);
                opacity: 0;
                font-family: "Inter";
              }
            }
          }

          .cards {
            width: 96%;
            display: flex;
            flex-wrap: wrap;
            gap: 0.2rem;
            margin-top: 2rem;

            @media (max-width: 960px) {
              width: 100%;
            }

            .card {
              display: flex;
              flex-direction: column;
              height: max-content;
              gap: 2rem;
              width: calc(33.333% - 0.2rem);
              background-color: #687bff;
              border-radius: 14px;

              &:nth-child(1) {
                animation: fly-down 4s ease-in-out infinite;

                @media (max-width: 490px) {
                  animation: fly-down4 4s ease-in-out infinite;
                }
              }

              &:nth-child(2) {
                animation: fly-up 4s ease-in-out infinite;
              }

              &:nth-child(3) {
                animation: fly-up2 4s ease-in-out infinite;

                .HText {
                  font-size: clamp(13px, 3vw, 18px);
                }
              }

              &:nth-child(4) {
                animation: fly-up2 4s ease-in-out infinite;
                gap: 1rem;

                .HText {
                  font-size: clamp(13px, 3vw, 18px);
                }

                @media (max-width: 490px) {
                  .HText {
                    span {
                      font-size: clamp(10px, 3vw, 22px);
                    }
                  }
                }

                @media (max-width: 430px) {
                  animation: fly-down3 4s ease-in-out infinite;
                }
              }

              &:nth-child(5) {
                animation: fly-down2 4s ease-in-out infinite;
              }

              .HText {
                padding: 1rem 0.5rem 0 1rem;
                color: rgba(255, 255, 255, 0.5);
                font-size: clamp(13px, 3.5vw, 20px);

                @media (max-width: 630px) {
                  padding: 0.5rem 0 0 0.5rem;
                }

                @media (max-width: 735px) {
                  font-size: clamp(13px, 3vw, 22px);
                }
              }

              .value {
                font-size: clamp(26px, 4vw, 48px);
                display: flex;
                justify-content: end;
                padding: 0 1rem 1rem 0;

                @media (max-width: 400px) {
                  padding: 0 0.5rem 1rem 0;
                }
              }
            }
          }
        }
      }

      .backBut {
        position: absolute;
        display: flex;
        width: 98%;
        margin: 0 auto;
        justify-content: end;
        transition: 1s;
        bottom: -30%;

        @media (max-width: 700px) {
          width: 95%;
        }

        span {
          margin: auto 0;
          margin-right: 1rem;
        }

        .image {
          width: 55px;
          height: 55px;
          border-radius: 50%;
          background-color: rgba(255, 255, 255, 1);
        }

        img {
          position: absolute;
          top: 35%;
          left: 28%;
          scale: 0.5;
          transform: translate(-50%, -50%);
          filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(255deg)
            brightness(100%) contrast(104%);
        }
      }
    }

    .downLeftCard {
      position: absolute;
      display: block;
      bottom: 0;
      left: 0;
      width: 49.75%;
      height: 42%;
      background-color: #4d60e2;
      border-radius: 35px;
      overflow: hidden;
      transition: 1s;
      margin-bottom: 7.5rem;
      cursor: pointer;

      &:hover {
        .lower {
          bottom: -25%;
        }
      }

      @media (max-width: 960px) {
        width: 100%;
        height: 24%;
        bottom: 26%;
        margin-bottom: 0;
      }

      &.active {
        width: 100%;
        height: 100%;
        margin-bottom: 0;

        @media (max-width: 960px) {
          top: 0;
        }

        & .header {
          bottom: 76%;
          right: none;
          left: 3%;
          width: 45%;

          @media (max-width: 960px) {
            bottom: 0;
            left: 0;
            top: 3%;
          }

          @media (max-width: 420px) {
            label {
              font-size: 1.35rem !important;
            }
          }

          label {
            display: block;
            font-size: clamp(28px, 4vw, 45px);
          }
        }

        & .rowList {
          &.active {
            .description {
              opacity: 1 !important;
              transform: translateY(0) !important;
            }

            .header {
              opacity: 1 !important;
              transform: translateY(0) !important;
            }
          }
        }

        & .rightSide {
          opacity: 1;
        }

        & .lower {
          bottom: 0;
          left: 0;
          margin: 0 0 1rem 2rem;
          letter-spacing: normal;

          @media (max-width: 960px) {
            top: 0%;
            left: 80%;
          }

          @media (max-width: 800px) {
            left: 75%;
          }

          @media (max-width: 660px) {
            left: 70%;
          }

          @media (max-width: 540px) {
            left: 65%;
          }

          @media (max-width: 465px) {
            left: 60%;
          }

          span {
            font-size: 100px;
            font-weight: 500;
            color: #defc65;

            @media (max-width: 550px) {
              font-size: 80px;
            }
          }
        }

        & .cards,
        .rightSide,
        .image {
          opacity: 1 !important;
        }

        & .backBut {
          bottom: 5%;

          .image {
            z-index: 50;
            cursor: pointer;
          }
        }
      }

      &.hidden {
        transition: 0.5s;
        transform: translateY(150%);

        @media (max-width: 960px) {
          transform: translate(105%, 0);
        }
      }

      .header {
        position: absolute;
        left: 5%;
        bottom: 0;
        display: block;
        width: 36%;
        margin: 0 3rem 3rem 0;
        font-family: "Inter";
        transition: 1s;

        @media (max-width: 960px) {
          left: 55%;
        }

        @media (max-width: 1100px) {
          width: 40%;
          margin: 0 0 3rem 2rem;
        }

        @media (max-width: 450px) {
          margin: 0 0 3rem 1.5rem;
          width: 35%;
        }

        @media (max-width: 420px) {
          margin: 0 0 2rem 0.6rem;
          width: 38%;
        }

        @media (max-width: 395px) {
          margin: 0 0 2rem 0.8rem;
          width: 40%;
        }

        label {
          display: block;
          font-size: clamp(15px, 2.5vw, 22px);

          @media (max-width: 960px) {
            font-size: clamp(15px, 3vw, 22px);
          }

          @media (max-width: 365px) {
            font-size: clamp(13px, 3vw, 22px);
          }
        }
      }

      .lower {
        position: absolute;
        display: flex;
        width: 98%;
        transition: 1s;
        left: 50%;
        bottom: -40%;

        @media (max-width: 1130px) {
          bottom: -35%;
        }

        @media (max-width: 960px) {
          left: 3%;
        }

        @media (max-width: 450px) {
          letter-spacing: -0.8rem;
          bottom: -35%;
          left: 4%;
        }

        @media (max-width: 380px) {
          left: 0;
        }

        span {
          font-size: clamp(156px, 30vw, 287px);
          font-weight: 300;
          color: #defc65;

          @media (max-width: 1130px) {
            font-size: clamp(156px, 22vw, 287px);
          }

          @media (max-width: 960px) {
            font-size: 240px;
          }

          @media (max-width: 500px) {
            font-size: 180px;
          }
        }
      }

      .contentBox {
        display: flex;
        .leftSide {
          width: 50%;

          @media (max-width: 960px) {
            position: absolute;
            width: 100%;
            height: 70%;
          }

          .image {
            width: 100%;
            height: 100%;
            transition: 1s;
            opacity: 0;
            img {
              position: absolute;
              top: 0;
              left: clamp(10rem, 25vw, 26rem);
              transform: translate(-50%, 5%);
              transition: 0.5s;
              filter: drop-shadow(0 4px 30px rgba(0, 0, 0, 0.5));

              @media (max-width: 960px) {
                width: 100%;
                height: 100%;
                object-fit: contain;
                transform: translate(-20%, -13%);
              }

              @media (max-width: 850px) {
                transform: translate(-20%, -15%);
              }

              @media (max-width: 490px) {
                transform: translate(-25%, -15%);
              }

              @media (max-width: 420px) {
                transform: translate(-30%, -18%);
              }

              @media (max-width: 375px) {
                transform: translate(-34%, -18%);
              }
            }
          }
        }

        .rightSide {
          width: 50%;
          height: 100%;
          transition: 1s;
          opacity: 0;

          @media (max-width: 960px) {
            width: 95%;
            margin: 75% auto 0 auto;
          }

          @media (max-width: 760px) {
            margin: 85% auto 0 auto;
          }

          .rowList {
            width: 95%;
            gap: 1rem;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-top: 2.5rem;

            @media (max-width: 960px) {
              width: 100%;
            }

            .row {
              display: flex;
              justify-content: space-between;
              overflow: hidden;
              border-top: 1px solid white;

              .header {
                position: relative;
                bottom: 0;
                left: 0 !important;
                right: 0;
                width: auto;
                margin: 1rem 0 0 1rem;
                color: rgba(255, 255, 255, 0.5);
                font-size: clamp(16px, 4vw, 25px);
                transition: 1s;
                transform: translateY(-150%);
                opacity: 0;
              }

              .description {
                width: 65%;
                font-size: clamp(13px, 2vw, 25px);
                margin: 1rem 0 0 1rem;
                transition: 1s;
                transform: translateY(-150%);
                opacity: 0;
                font-family: "Inter";
              }
            }
          }

          .cards {
            width: 96%;
            display: flex;
            flex-wrap: wrap;
            gap: 0.2rem;
            margin-top: 2rem;

            @media (max-width: 960px) {
              width: 100%;
            }

            .card {
              display: flex;
              flex-direction: column;
              height: max-content;
              gap: 2rem;
              width: calc(33.333% - 0.2rem);
              background-color: #687bff;
              border-radius: 14px;

              &:nth-child(1) {
                animation: fly-down 4s ease-in-out infinite;
              }

              &:nth-child(2) {
                animation: fly-up 4s ease-in-out infinite;
              }

              &:nth-child(3) {
                animation: fly-up2 4s ease-in-out infinite;
              }

              &:nth-child(4) {
                animation: fly-up2 4s ease-in-out infinite;
              }

              &:nth-child(5) {
                animation: fly-down2 4s ease-in-out infinite;
              }

              .HText {
                padding: 1rem 1rem 0 1rem;
                color: rgba(255, 255, 255, 0.5);
                font-size: clamp(13px, 3.5vw, 22px);
              }

              .value {
                font-size: clamp(25px, 4vw, 48px);
                display: flex;
                justify-content: end;
                padding: 0 1rem 1rem 0;

                @media (max-width: 370px) {
                  padding: 0 0.5rem 1rem 0;
                }
              }
            }
          }
        }
      }

      .backBut {
        position: absolute;
        display: flex;
        width: 98%;
        margin: 0 auto;
        justify-content: end;
        transition: 1s;
        bottom: -30%;

        @media (max-width: 700px) {
          width: 95%;
        }

        span {
          margin: auto 0;
          margin-right: 1rem;
        }

        .image {
          width: 55px;
          height: 55px;
          border-radius: 50%;
          background-color: rgba(255, 255, 255, 1);
        }

        img {
          position: absolute;
          top: 35%;
          left: 28%;
          scale: 0.5;
          transform: translate(-50%, -50%);
          filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(255deg)
            brightness(100%) contrast(104%);
        }
      }
    }
  }
}

.serviceBlock {
  width: 95%;
  margin: 16rem auto 0 auto;

  @media (max-width: 560px) {
    margin: 8rem auto 0 auto !important;
  }

  .Row {
    font-size: 41px;
    font-family: "Inter";

    @media (max-width: 560px) {
      text-align: center;
    }
  }

  .serviceCards {
    position: relative;
    width: 100%;
    height: 30rem;
    display: flex;
    margin-top: 4rem;
    justify-content: center;
    overflow: hidden;

    @media (max-width: 960px) {
      height: 55rem;

      .upperCard {
        width: 100%;
        height: 21%;

        &.active {
          height: 40rem;

          @media (max-width: 700px) {
            height: 47rem;
          }

          .lower {
            transition: 0.5s;
            bottom: none;
            top: 5%;

            span {
              opacity: 0;
            }
          }

          .cards {
            width: 95%;
            margin: 0 auto 0 auto;
            height: calc(60% - 2rem);

            @media (max-width: 750px) {
              height: calc(65% - 2rem);
            }
          }

          .orderBut {
            right: 0;
            margin: 0 1rem 2rem 0;
          }
        }
      }

      .downCard {
        width: 100%;
        height: 21%;
        top: 23%;

        .header {
          bottom: none;
          top: 0;
          margin: 2rem 0 0 2rem;
        }

        &.active {
          top: 0;
          height: 40rem;

          @media (max-width: 700px) {
            height: 44rem;
          }

          .header {
            bottom: none;
          }

          .lower {
            transition: 0.5s;
            bottom: none;
            top: 5%;

            span {
              opacity: 0;
            }
          }

          .cards {
            width: 95%;
            margin: 0 auto 0 auto;
            height: calc(60% - 2rem);

            @media (max-width: 750px) {
              height: calc(70% - 2rem);
            }
          }

          .orderBut {
            right: 0;
            margin: 0 1rem 2rem 0;
          }
        }

        &.hidden {
          transition: 0.5s;
          transform: translate(-110%, 0);
        }
      }

      .rightCard {
        width: 100%;
        height: 54%;
        bottom: 0%;

        .bigCard {
          display: flex;
          justify-content: center;
        }

        &.active {
          height: 55rem;

          .header {
            top: 5%;
            bottom: none;
          }

          .lower {
            transition: 0.5s;
            bottom: none;
            top: 5%;
            z-index: 500;

            span {
              opacity: 0;
            }
          }

          .cards {
            width: 95%;
            margin: 0 auto 0 auto;
            height: calc(60% - 2rem);

            @media (max-width: 750px) {
              height: calc(73% - 2rem);
            }
          }

          .orderBut {
            right: 0;
            margin: 0 1rem 2rem 0;
            z-index: 505;
          }

          .bigCard {
            .header {
              label {
                margin-left: 1rem;
              }
            }

            .cards {
              .card {
                flex-basis: calc(50% - 2rem);
              }
            }
          }
        }

        &.hidden {
          transition: 0.5s;
          transform: translateX(30%);
        }
      }
    }
  }

  .upperCard {
    position: absolute;
    display: flex;
    align-items: center;
    left: 0;
    width: 50%;
    height: 49%;
    background-color: #4d60e2;
    border-radius: 35px;
    overflow: hidden;
    transition: 1s;

    &.active {
      width: 100%;
      height: 100%;

      & .lower {
        bottom: -20%;
      }

      & .header {
        width: 40%;
      }

      & .cards {
        opacity: 1;
      }

      & .orderBut {
        bottom: 0;
      }
    }

    &.hidden {
      transition: 0.5s;
      transform: translateY(-110%);
    }

    .header {
      position: absolute;
      top: 0;
      display: block;
      width: 40%;
      margin: 2rem 0 0 2rem;
      font-family: "Inter";
      label {
        display: block;
        font-size: clamp(35px, 5vw, 55px);
        text-wrap: nowrap;
      }

      span {
        display: block;
        font-size: clamp(18px, 2.2vw, 25px);
        text-wrap: nowrap;
      }
    }

    .lower {
      position: absolute;
      display: flex;
      width: 98%;
      margin: 0 auto;
      justify-content: end;
      font-size: clamp(12px, 2vw, 25px);
      transition: 1s;
      bottom: 5%;

      span {
        margin: auto 0;
        margin-right: 1rem;
        transition: 1s;
      }

      .image {
        width: 55px;
        height: 55px;
        border-radius: 50%;
        background-color: rgba(255, 255, 255, 0.5);
      }

      img {
        position: absolute;
        top: 35%;
        left: 28%;
        scale: 0.5;
        transform: translate(-50%, -50%);
      }
    }

    .cards {
      display: flex;
      width: 55%;
      height: calc(100% - 2rem);
      flex-wrap: wrap;
      gap: 0.5rem;
      margin: 1rem 1rem 1rem 44%;
      transition: 1s;
      opacity: 0;

      .card {
        flex: 1 1 calc(50% - 1rem);
        background-color: #8695fc;
        border-radius: 15px;

        header {
          display: flex;
          height: 40%;
          margin-right: 1rem;
          margin-top: 1rem;
          justify-content: end;

          .image {
            width: 76px;
            height: 76px;
            background-color: #6d7ff9;
            border-radius: 15px;

            img {
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }
        }

        .text {
          width: 220px;
          position: absolute;
          bottom: 10%;
          font-size: clamp(12px, 2.5vw, 17px);
          font-family: "Inter";
          padding-left: 1rem;

          @media (max-width: 550px) {
            width: 150px;
          }

          @media (max-width: 420px) {
            width: 130px;
          }
        }
      }
    }

    .orderBut {
      position: absolute;
      bottom: -50%;
      margin: 0 0 1rem 2rem;
      background-color: #ffffff;
      color: black;
      font-size: 16px;
      text-align: center;
      font-family: "Inter";
      padding: 1rem 6%;
      border-radius: 60px;
      transition: 1s;
      cursor: pointer;

      &:hover {
        box-shadow: 0px 0px 20px 0px rgba(255, 255, 255, 1);
      }
    }
  }

  .downCard {
    position: absolute;
    display: flex;
    align-items: center;
    left: 0;
    bottom: 0;
    width: 50%;
    height: 49%;
    background-color: #1c1c1c;
    border-radius: 35px;
    overflow: hidden;
    transition: 1s;

    &.active {
      width: 100%;
      height: 100%;

      & .lower {
        bottom: -20%;
      }

      & .header {
        width: 40%;
        bottom: 70%;
      }

      & .cards {
        opacity: 1;
      }

      & .orderBut {
        bottom: 0;
      }
    }

    &.hidden {
      bottom: -100%;
    }

    .header {
      position: absolute;
      display: block;
      bottom: 0;
      width: 40%;
      margin: 0 0 2rem 2rem;
      font-family: "Inter";
      transition: 1s;
      label {
        display: block;
        font-size: clamp(35px, 5vw, 55px);
        margin-bottom: auto;
        text-wrap: nowrap;
      }

      span {
        display: block;
        font-size: clamp(15px, 2.2vw, 25px);
        text-wrap: nowrap;
      }
    }

    .lower {
      position: absolute;
      display: flex;
      width: 98%;
      margin: 0 auto;
      justify-content: end;
      font-size: clamp(12px, 2vw, 25px);
      transition: 1s;
      bottom: 5%;

      span {
        margin: auto 0;
        margin-right: 1rem;
        transition: 1s;
      }

      .image {
        width: 55px;
        height: 55px;
        border-radius: 50%;
        background-color: rgba(255, 255, 255, 0.5);
      }

      img {
        position: absolute;
        top: 35%;
        left: 28%;
        scale: 0.5;
        transform: translate(-50%, -50%);
      }
    }

    .cards {
      display: flex;
      width: 55%;
      height: calc(100% - 2rem);
      flex-wrap: wrap;
      gap: 0.5rem;
      margin: 1rem 1rem 1rem 44%;
      transition: 1s;
      opacity: 0;

      .card {
        flex: 1 1 calc(50% - 1rem);
        background-color: #323232;
        border-radius: 15px;

        header {
          display: flex;
          height: 40%;
          margin-right: 1rem;
          margin-top: 1rem;
          justify-content: end;

          .image {
            width: 76px;
            height: 76px;
            background-color: #1c1c1c;
            border-radius: 15px;

            img {
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }
        }

        .text {
          width: 220px;
          position: absolute;
          bottom: 10%;
          font-size: clamp(12px, 2.5vw, 17px);
          font-family: "Inter";
          padding-left: 1rem;

          @media (max-width: 550px) {
            width: 150px;
          }

          @media (max-width: 420px) {
            width: 130px;
          }
        }
      }
    }

    .orderBut {
      position: absolute;
      bottom: -50%;
      margin: 0 0 1rem 2rem;
      background-color: #ffffff;
      color: black;
      font-size: 16px;
      text-align: center;
      font-family: "Inter";
      padding: 1rem 6%;
      border-radius: 60px;
      transition: 1s;
      cursor: pointer;

      &:hover {
        box-shadow: 0px 0px 20px 0px rgba(255, 255, 255, 1);
      }
    }
  }

  .rightCard {
    position: absolute;
    display: block;
    right: 0;
    width: 49%;
    height: 100%;
    background-color: #deff50;
    border-radius: 35px;
    overflow: hidden;
    transition: 1s;
    color: black;

    &.active {
      width: 100%;

      .header {
        width: 50% !important;
        left: 0;

        label {
          font-size: 55px !important;
          letter-spacing: 0 !important;
          text-align: start !important;
          margin-left: 3rem;
          margin-top: 1rem;
          font-weight: 500 !important;
        }
      }

      .bomb {
        opacity: 0;
      }

      & .lower {
        bottom: -20% !important;
      }

      & .cards {
        opacity: 1 !important;
      }

      & .orderBut {
        bottom: 0 !important;
      }
    }

    &.hidden {
      right: -80%;
    }

    .bigCard {
      display: flex;
      height: 100%;
      flex-direction: column;
      justify-content: space-between;
      gap: 6rem;
      overflow: hidden;

      .header {
        position: absolute;
        display: block;
        top: 5%;
        width: 100%;
        font-family: "Inter";
        letter-spacing: -8px;
        z-index: 10;

        @media (max-width: 960px) {
          height: 100%;
        }

        label {
          display: block;
          width: 100%;
          font-size: clamp(135px, 18vw, 251px);
          font-weight: 800;
          text-align: center;
          transform: translateY(-20%);
          transition: 1s;

          @media (max-width: 960px) {
            font-size: clamp(135px, 30vw, 251px);
          }
        }

        span {
          display: block;
          font-size: 25px;
        }
      }

      .lower {
        position: absolute;
        display: flex;
        width: 98%;
        margin: 0 auto;
        justify-content: end;
        font-size: clamp(12px, 2vw, 25px);
        transition: 1s;
        bottom: 5%;
        color: black;

        span {
          margin: auto 0;
          margin-right: 1rem;
        }

        .image {
          width: 55px;
          height: 55px;
          border-radius: 50%;
          background-color: rgba(255, 255, 255, 1);
        }

        img {
          position: absolute;
          top: 35%;
          left: 28%;
          scale: 0.5;
          transform: translate(-50%, -50%);
          filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(255deg)
            brightness(100%) contrast(104%);
        }
      }

      .bomb {
        position: absolute;
        top: 75%;
        left: 48%;
        transform: translate(-50%, -50%);
        z-index: 20;
        width: 100%;
        height: 100%;
        object-fit: contain;
        scale: 1.3;
        transition: 1s;
      }

      .cards {
        display: flex;
        width: 72%;
        height: calc(100% - 2rem);
        flex-wrap: wrap;
        gap: 0.5rem;
        margin: 1rem 1rem 1rem 27%;
        transition: 1s;
        opacity: 0;

        :nth-of-type(1) {
          header {
            .image {
              img {
                top: 120% !important;
                left: 150% !important;
                scale: 2.5;
              }
            }
          }
        }

        :nth-of-type(1),
        :nth-of-type(2),
        :nth-of-type(6) {
          header {
            .image {
              img {
                filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(255deg)
                  brightness(100%) contrast(104%);
              }
            }
          }
        }

        :nth-of-type(5) {
          header {
            .image {
              img {
                width: 100%;
              }
            }
          }
        }

        .card {
          flex: 1 1 calc(33% - 1rem);
          background-color: #f3ffbe;
          border-radius: 15px;

          header {
            display: flex;
            height: 40%;
            margin-right: 1rem;
            margin-top: 1rem;
            justify-content: end;

            .image {
              width: 76px;
              height: 76px;
              background-color: #deff50;
              border-radius: 15px;

              img {
                height: 100%;
                object-fit: scale-down;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
              }
            }
          }

          .text {
            width: 200px;
            position: absolute;
            bottom: 10%;
            font-size: clamp(12px, 1.5vw, 17px);
            font-family: "Inter";
            padding-left: 1rem;
            transition: 0.5s;

            @media (max-width: 550px) {
              width: 150px;
            }

            @media (max-width: 380px) {
              width: 130px;
            }

            @media (max-width: 330px) {
              width: 120px;
            }
          }
        }
      }

      .orderBut {
        position: absolute;
        bottom: -50%;
        margin: 0 0 1rem 2rem;
        background-color: #1c1c1c;
        color: white;
        font-size: 16px;
        text-align: center;
        font-family: "Inter";
        padding: 1rem 6%;
        border-radius: 60px;
        transition: 1s;
        cursor: pointer;
        z-index: 22;

        &:hover {
          box-shadow: 0px 0px 20px 0px rgb(0, 0, 0);
        }
      }
    }
  }
}

.contactBlock {
  width: 95%;
  margin: 0 auto;
  border-radius: 15px;
  overflow: hidden;
  background-color: #deff50;
  margin-bottom: 5rem;

  // .contactContent {
  //   width: 95%;
  //   margin: 0 auto;
  //   display: flex;
    


    .container {
      position: relative;

      &__contact {
        width: 20%;
        padding: 2rem 0 0 2rem;

        @media (max-width: 900px) {
          width: 100%;
          position: absolute;
          text-align: center;
          padding: 0;
          margin-top: 2.5rem;
        }

        label {
          width: 90%;
          font-family: "Inter";
          font-weight: 500;
          color: rgb(0, 0, 0);
          font-size: clamp(50px, 5vw, 64px);

          @media (max-width: 768px) {
            font-size: clamp(40px, 5vw, 44px);
          }

        }
      }
    }

    .cardGrid {
      width: 100%;
      margin: 0;
      margin-top: 4rem;

      @media (max-width: 1050px) {
        width: 100%;
      }

      @media (max-width: 900px) {
        width: 100%;
        margin: 0;
      }

      .socialContainer {
        width: 80%;
        position: relative;
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        margin: 0 0 5rem 6rem;
        gap: 8%;

        @media (max-width: 768px) {
          flex-wrap: wrap;
          margin: 8rem 0 3rem -1rem;
          width: 100%;
        }

        .container {
          width: 20%;
          height: 20%;
          text-decoration: none;

          @media (max-width: 768px) {
            width: 30%;
          }


          .socialCard {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
           
            position: relative;
            width: 130%;
            height: 280px;
            border-radius: 30px;
            transition: 0.15s;
            transition: background-color 0.6s;

            @media (max-width: 600px) {
              height: 250px;
            }

            @media (max-width: 500px) {
              height: 200px;
            }

            @media (max-width: 385px) {
              height: 180px;
            }

            img {
              transform: scale(0.8);
              transition: transform 0.7s;
              filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(255deg)
                brightness(100%) contrast(104%);

              @media (max-width: 600px) {
                scale: 0.6;
              }

              @media (max-width: 385px) {
                scale: 0.4;
              }
            }

            &:hover {
              cursor: pointer;
              background-color: #000000;

              img {
                transform: scale(1);
                filter: sepia(40%) saturate(2501%) hue-rotate(16deg)
                  brightness(103%);

                @media (max-width: 600px) {
                  scale: 0.6;
                }

                @media (max-width: 385px) {
                  scale: 0.4;
                }
              }

              .title {
                color: #deff50;
              }
            }

            .image {
              position: absolute;
              margin-top: 0;
              left: 50%;
              top: 40%;
              transform: translate(-50%, -50%);
              text-align: center;

              img {
                max-width: 100%;
                height: auto;
              }
            }
            .title {
              display: block;
              text-align: center;
              font-size: clamp(12px, 3vw, 15px);
              margin-top: auto;
              margin-bottom: 3rem;
              text-transform: uppercase;
              font-family: "Inter";
              font-weight: 800;
              transition: 0.15s;
              color: rgb(0, 0, 0);
            }
          }
        }
        
      }
    }
  }
// }

.foundersBlock {
  width: 95%;
  margin: 6rem auto 0 auto;

  .Row {
    display: block;
    text-align: center;
    font-size: 35px;
    font-family: "Inter";
    font-weight: 300;
    color: rgba(255, 255, 255, 0.5);
  }

  .image {
    height: clamp(38rem, 30vw, 80rem);
    display: flex;
    justify-content: center;
    margin-top: 2rem;

    @media (max-width: 800px) {
      height: 400px;
    }

    @media (max-width: 560px) {
      height: 300px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      z-index: 2;
    }

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 28%;
      background: rgb(2, 2, 2);
      filter: blur(15px);
      left: 0;
      bottom: -10%;
      z-index: 3;

      @media (max-width: 430px) {
        left: -5%;
      }
    }
  }

  .name {
    position: absolute;
    display: flex;
    z-index: 2;
    font-size: clamp(81px, 30vw, 200px);
    font-family: "Inter";
    top: clamp(25%, 50vw, 40%);
    left: 100%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    text-wrap: nowrap;
    z-index: -1;
    animation: animate_text_left 45s linear infinite;
    margin-top: -5rem;
    will-change: transform;

    span {
      margin-left: 5rem;
    }

    &.fadeIn {
      opacity: 1;
      transition: opacity 0.3s ease-in;
      z-index: -1;
    }

    &.fadeOut {
      opacity: 0;
      transition: opacity 0.3s ease-out;
      z-index: -1;
    }
  }

  .descriptionContainer {
    z-index: 4;
    height: max-content;

    .header {
      display: block;
      text-align: center;

      label,
      span {
        width: 100%;
        display: block;
        font-family: "Inter";
      }

      label {
        color: #deff50;
        font-size: clamp(12px, 3vw, 22px);
      }

      span {
        margin-top: 1rem;
        font-size: clamp(20px, 4vw, 35px);
        font-weight: 500;
      }
    }

    .description {
      display: block;
      width: 60%;
      margin: 1rem auto 0 auto;
      text-align: center;
      font-size: clamp(14px, 1.3vw, 35px);
      color: rgba(255, 255, 255, 0.65);

      @media (max-width: 650px) {
        width: 80%;
      }

      b {
        color: rgba(255, 255, 255, 1);
      }
    }
  }

  .reviewNav {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 2rem;

    button {
      background-color: #2a2a2a;
      width: 55px;
      height: 55px;
      color: white;
      border: none;
      border-radius: 50%;
      margin: 0 10px;
      cursor: pointer;
      transition: background-color 0.3s;
      font-size: 70px;

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        filter: invert(100%) sepia(0%) saturate(196%) hue-rotate(125deg)
          brightness(112%) contrast(100%);
      }

      &:nth-child(1) img {
        left: 45%;
      }
      &:nth-child(2) img {
        left: 53%;
      }

      &:hover {
        background-color: #ddd;

        img {
          filter: invert(0%) sepia(83%) saturate(7430%) hue-rotate(10deg)
            brightness(111%) contrast(114%);
        }
      }
    }
  }
}




// .reviewsBlock {
//   width: 85%;
//   margin: 0 auto;
//   margin-bottom: 10rem;

//   @media (max-width: 768px) {
//     margin-bottom: 5rem;
//   }

//   .Row {
//     width: 100%;
//     text-align: center;
//     font-size: 41px;
//     font-family: "Inter";
//     margin-bottom: 8rem;
//     margin-top: 10rem;

//     @media (max-width: 600px) {
//       margin-top: 7rem;
//     }
//   }

//   .reviewsStatic {
//     display: flex;
//     margin: 2rem auto 2rem auto;
    
//     height: auto;
//     overflow: hidden;
//     column-gap: 2rem;

//     @media (max-width: 900px) {
//       flex-direction: column;

//       .firstRow {
//         width: 100% !important;
//       }

//       .secondRow {
//         width: 100% !important;
//         margin-top: 1rem;
//       }
//     }

//   .reviewsStatic {
//     display: flex;
//     flex-direction: column;
//     column-gap: 2rem;
//     animation: scrollReviews 10s linear infinite; // Animation that scrolls the reviews
//   }

//     .firstRow,
//     .secondRow {
//       width: 50%;
//       display: flex;
//       flex-direction: column;
//       align-items: center;
//       row-gap: 1rem;
//     }

//     .card {
//       display: block;
//       width: 100%;
//       height: max-content;
//       background-color: #1e1e1e;
//       color: white;
//       border-radius: 10px;
//       padding: 1rem 0;
//       text-align: left;
//       transition: 0.5s;

//       .reviewCardHeader {
//         display: flex;
//         width: 95%;
//         margin: 0.5rem auto 0 auto;
//         .like {
//           position: absolute;
//           right: 0;
//           top: 0;
//           padding: 0.5rem;
//           transition: 0.5s;
//           border-radius: 10px;
//           background-color: #2a2a2a;
//         }

//         .name {
//           width: 95%;
//           margin: 0 auto;
//           padding: 0 0 1rem 0;
//           font-family: "Inter";
//           font-weight: 500;

//           span {
//             display: block;
//             width: 100%;
//             font-size: clamp(17px, 1.8vw, 30px);
//             padding-top: 5px;

//             @media (max-width: 980px) {
//               width: 80%;
//             }

//             @media (max-width: 380px) {
//               width: 75%;
//             }
//           }

//           label {
//             display: block;
//             width: 100%;
//             font-size: 14px;
//             color: rgba(255, 255, 255, 0.7);
//           }
//         }
//       }

//       .reviewDescriptionText {
//         width: 90%;
//         display: flex;
//         flex-direction: column;
//         align-items: start;
//         margin: 0 auto;
//         transition: 0.5s;
//         font-size: 15px;
//         line-height: 23px;
//         color: rgba(255, 255, 255, 0.8);
//         padding: 1rem 0 2rem 0;
//         font-family: "Inter";

//         p {
//           width: 85%;
//           margin-bottom: 0.2rem;
//           font-size: 16px;
//           color: rgba(255, 255, 255, 0.7);
//         }
//       }

//       &:hover {
//         opacity: 1;
//         color: black;
//         background-color: #d6dcdc;

//         .name {
//           label {
//             color: black;
//           }
//         }

//         .like {
//           filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(255deg)
//             brightness(100%) contrast(101%);
//         }
//       }
//     }
//   }
// }


//         .reviewDescriptionText {
//           p {
//             color: black;
//           }
//         }
//       }
//     }
//   }
// }
.reviewsBlock {
  width: 85%;
  margin: 0 auto;
  margin-bottom: 10rem;
  position: relative;
  height: auto; // Adjust to auto to avoid overlapping with other blocks
  overflow: hidden; // Prevent overflow from showing

  @media (max-width: 768px) {
    margin-bottom: 5rem;
  }

  .Row {
    width: 100%;
    text-align: center;
    font-size: 41px;
    font-family: "Inter";
    margin-bottom: 6rem;
    margin-top: 10rem;

    @media (max-width: 600px) {
      margin-top: 7rem;
      font-size: 30px; // Измените размер шрифта на мобильных устройствах
    }
  }

  .reviewsStatic {
    display: flex;
    overflow: hidden;
    position: relative;
    height: 600px; // Установите высоту в зависимости от ваших требований
    padding: 0; 
    margin: 0; 

    &::before,
    &::after {
        content: '';
        position: absolute;
        left: -5%;
        right: -5%;
        height: 70px; // Высота размытия, настройте по своему усмотрению
        background: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.8));
        filter: blur(10px); // Уровень размытия, настройте по своему усмотрению
        z-index: 1; // Убедитесь, что псевдоэлементы находятся над содержимым
    }

    &::before {
        top: -8%; // Псевдоэлемент для верхней границы
    }

    &::after {
        bottom: -8%; // Псевдоэлемент для нижней границы
        transform: rotate(180deg); // Поворачиваем градиент для нижней границы
    }

    @media (max-width: 768px) {
      flex-direction: column; // Измените направление на столбец
      height: 700px; // Установите высоту в auto
    }

    .firstRow {
      width: 50%;
      display: flex;
      flex-direction: column;
      animation: scrollReviews 45s linear infinite;

      @media (max-width: 768px) {
        width: 100%; // Установите ширину в 100%
        animation: scrollReviewsMob 250s linear infinite; // Увеличьте время анимации
      }
    }

    .secondRow {
      width: 50%;
      display: flex;
      flex-direction: column;
      animation: scrollReviewsBack 46s linear infinite;

      @media (max-width: 768px) {
        display: none;
      }
    }

    @keyframes scrollReviews {
      0% {
        transform: translateY(0);
      }
      50% {
        transform: translateY(-100%); // Halfway point, reviews move up
      }
      100% {
        transform: translateY(0); // Move up further for continuous effect
      }
    }

    @keyframes scrollReviewsMob {
      0% {
        transform: translateY(0);
      }
      50% {
        transform: translateY(-100%); // Halfway point, reviews move up
      }
      100% {
        transform: translateY(0); // Move up further for continuous effect
      }
    }

    @keyframes scrollReviewsBack {
      0% {
        transform: translateY(-100%); // Начальная позиция (вверху)
      }
      50% {
        transform: translateY(0); // Половина пути, отзывы перемещаются вниз
      }
      100% {
        transform: translateY(-100%); // Перемещаемся вниз дальше для непрерывного эффекта
      }
    }
  }

  .card {
    display: block;
    width: 90%;
    height: max-content;
    background-color: #1e1e1e;
    color: white;
    border-radius: 10px;
    padding: 1rem;
    text-align: left;
    transition: 0.5s;
    margin: 1rem 0 1rem 0;

    @media (max-width: 768px) {
      width: 95%; // Убедитесь, что карточка занимает 95% ширины на мобильном
      margin: 1rem auto; // Настройте отступы
    }

    .reviewCardHeader {
      display: flex;
      width: 95%;
      margin: 0.5rem auto 0 auto;

      .like {
        position: absolute;
        right: 0;
        top: 0;
        padding: 0.5rem;
        transition: 0.5s;
        border-radius: 10px;
        background-color: #2a2a2a;
      }

      .name {
        width: 95%;
        margin: 0 auto;
        padding: 0 0 1rem 0;
        font-family: "Inter";
        font-weight: 500;

        span {
          display: block;
          width: 100%;
          font-size: clamp(17px, 1.8vw, 30px);
          padding-top: 5px;
          color: #d6dcdc;

          @media (max-width: 980px) {
            width: 80%;
          }

          @media (max-width: 380px) {
            width: 75%;
          }
        }

        label {
          display: block;
          width: 100%;
          font-size: 14px;
          color: rgba(255, 255, 255, 0.7);
        }
      }
    }

    .reviewDescriptionText {
      width: 90%;
      display: flex;
      flex-direction: column;
      align-items: start;
      margin: 0 auto;
      transition: 0.5s;
      font-size: 15px;
      line-height: 23px;
      color: rgba(255, 255, 255, 0.8);
      padding: 1rem 0 2rem 0;
      font-family: "Inter";

      p {
        width: 85%;
        margin-bottom: 0.2rem;
        font-size: 16px;
        color: rgba(255, 255, 255, 0.7);
      }
    }

    &:hover {
      opacity: 1;
      color: black;
      background-color: #d6dcdc;

      .name {
        label {
          color: black;
        }
        span {
          color: black !important;
        }
      }

      .reviewDescriptionText {
        p {
          color: #000000;
        }
      }

      .like {
        filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(255deg)
          brightness(100%) contrast(101%);
      }
    }
  }
}

.formButton {
  display: flex;
  align-items: end;
  width: 95%;
  margin: 0 auto;
  background-color: #4d60e2;
  border-radius: 30px;
  margin-bottom: 10rem;

  @media (max-width: 550px) {
    margin-bottom: 5rem;
  }

  .label {
    width: 70%;
    flex-direction: column;
    margin-top: 15rem;
    padding: 0 0 2rem 2rem;

    @media (max-width: 700px) {
      margin-top: 8rem;
    }

    @media (max-width: 550px) {
      margin-top: 5rem;
      padding: 0 0 1.5rem 1rem;
    }

    label {
      display: block;
      width: 100%;
      font-size: clamp(23px, 6vw, 62px);
      font-family: "Inter";
      margin-bottom: 0.5rem;
    }

    span {
      display: block;
      width: 120%;
      font-size: clamp(14px, 3vw, 30px);
      font-family: "Inter";
    }
  }

  .buttonLayout {
    display: flex;
    justify-content: end;
    width: 30%;
    padding: 0 2rem 2rem 0;

    @media (max-width: 650px) {
      scale: 0.6;
    }

    @media (max-width: 550px) {
      padding: 0 0rem 0.7rem 0;
      scale: 0.5;
    }

    .button {
      display: flex;
      justify-content: center;
      background-color: white;
      border-radius: 12px;
      padding: 1rem clamp(1rem, 4vw, 2rem);
      cursor: pointer;

      img {
        object-fit: contain;
        filter: invert(36%) sepia(69%) saturate(3418%) hue-rotate(206deg)
          brightness(91%) contrast(89%);
      }
    }
  }
}

.fadeIn {
  opacity: 1;
  transition: opacity 0.3s ease-in;
}

.fadeOut {
  opacity: 0;
  transition: opacity 0.3s ease-out;
}

@keyframes animate_text_left {
  from {
    transform: translate3D(0, 0, 0);
    -webkit-transform: translate3D(0, 0, 0);
    -moz-transform: translate3D(0, 0, 0);
  }
  to {
    transform: translate3D(-100%, 0, 0);
    -webkit-transform: translate3D(-100%, 0, 0);
    -moz-transform: translate3D(-100%, 0, 0);
  }
}

@keyframes animate_text_up {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(-100%);
  }
}

@keyframes animate_text_down {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(100%);
  }
}


@keyframes fly-up {
  0% {
    transform: translateY(1.5rem);
  }
  50% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(1.5rem);
  }
}

@keyframes fly-down {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(1rem);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes fly-up2 {
  0% {
    transform: translateY(1rem);
  }
  50% {
    transform: translateY(0.5rem);
  }
  100% {
    transform: translateY(1rem);
  }
}

@keyframes fly-up3 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-1rem);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes fly-down2 {
  0% {
    transform: translateY(2rem);
  }
  50% {
    transform: translateY(1.5em);
  }
  100% {
    transform: translateY(2rem);
  }
}

@keyframes fly-down3 {
  0% {
    transform: translateY(-1.5rem);
  }
  50% {
    transform: translateY(-2rem);
  }
  100% {
    transform: translateY(-1.5rem);
  }
}

@keyframes fly-down4 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(0.5rem);
  }
  100% {
    transform: translateY(0);
  }
}
