input {
  border: none;
  outline: none;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  overflow: hidden;
  overflow-y: auto;
  transition: opacity 0.3s ease;
  z-index: 999;
}

.modalWrapper {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100%;
}

.modalContainer {
  background: #4d60e2;
  position: relative;
  padding: 2.5rem 1.5rem;
  width: 90%;
  max-width: 1500px;
  border-radius: 12px;
  border: 0.0625rem solid #4d60e2;
  overflow: hidden;

  @media (max-width: 700px) {
    display: flex;
    flex-direction: column;
    scale: 0.85;
  }
}

.modalHeader {
  display: flex;
  flex-direction: column;
}

.modalHeader label {
  color: white;
  width: 40rem;
  font-family: "Inter";
  font-weight: 500;
  font-size: clamp(21px, 4.5vw, 40px);

  @media (max-width: 700px) {
    width: 70%;
  }
}

.modalHeader p {
  color: white;
  font-family: "Inter";
  font-size: clamp(15px, 3vw, 22px);
  font-weight: 200;
  margin-top: 1.5rem;
}

.inputs {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  width: 100%;
}

.formGroup {
  display: flex;
  width: 100%;
  gap: 3rem;
  margin-top: 2rem;

  @media (max-width: 700px) {
    flex-direction: column;

    &:nth-child(1) {
      gap: 5rem;

      input:nth-child(2) {
        z-index: 95;
        padding: 0.75rem 0.75rem 0.5rem 0 !important;

        @media (max-width: 289px) {
          padding: 0.75rem 0.75rem 1.25rem 0 !important;
        }
      }
    }

    &:nth-child(2) {
      position: absolute;
      gap: 5rem;
      top: 4rem;

      input:nth-child(2) {
        padding: 0.75rem 0.75rem 0.5rem 0 !important;
        @media (max-width: 345px) {
          padding: 0.75rem 0.75rem 1.25rem 0 !important;
        }
      }
    }

    &:nth-child(3) {
      gap: 1rem;
      margin-top: 5rem;

      input:nth-child(1) {
        padding: 0.75rem 0.75rem 0.5rem 0 !important;
        @media (max-width: 530px) {
          padding: 0.75rem 0.75rem 1.25rem 0 !important;
        }
        @media (max-width: 324px) {
          padding: 0.75rem 0.75rem 2.5rem 0 !important;
        }
      }
    }
  }

  .img2 {
    left: clamp(9rem, 25vw, 11.5rem);

    @media (max-width: 700px) {
      top: 1.4rem !important;
    }
  }

  .img1 {
    left: clamp(6rem, 18vw, 7.5rem);

    @media (max-width: 700px) {
      top: 1.35rem !important;
    }
  }

  .img1,
  .img2 {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
  }
}

input::placeholder {
  color: rgba(255, 255, 255, 0.5);
  font-size: clamp(15px, 2.6vw, 20px);
  text-wrap: wrap;
}

input {
  flex: 1 1 calc(50% - 1rem);
  padding: 0.75rem 0.75rem 0.5rem 0;
  border-bottom: 1px solid white;
  background-color: transparent;
  color: rgba(255, 255, 255);
  font-size: 20px;
}

.formGroup {
  &:nth-child(1) {
    input:nth-child(2) {
      @media (max-width: 725px) {
        padding: 0.75rem 0.75rem 1.5rem 0;
      }
    }
  }
  &:nth-child(2) {
    input:nth-child(2) {
      @media (max-width: 905px) {
        padding: 0.75rem 0.75rem 1.5rem 0;
      }
    }
  }
}

.fileInput {
  display: flex;
  align-items: center;
  position: relative;
  border-bottom: 1px solid white;
  flex: 1 1 calc(50% - 1rem);
  padding: 0.75rem 0.75rem 0.75rem 0;
  font-size: 20px;

  &.active {
    border: none;
  }
}

.fileInput input[type="file"] {
  width: 100%;
  position: absolute;
  opacity: 0;
  z-index: 10;
}

.fileInput span {
  width: 80%;
  text-align: start;
  color: rgba(255, 255, 255, 0.5);
  pointer-events: none;
  font-size: clamp(15px, 2.6vw, 20px);
}

.image {
  position: absolute;
  top: 0%;
  right: 0%;
  padding: 0.2rem;
  border-radius: 8px;
  cursor: pointer;
  transform: translate(-20%, 20%);
  width: 33px;
  height: 30px;
  background-color: transparent;

  img {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.selectedFiles {
  display: flex;
  flex-wrap: wrap;
  row-gap: 0.5rem;
  padding-bottom: 0.5rem;
  position: absolute;
  left: 0;
  top: 0;
  width: 90%;
  color: rgba(255, 255, 255, 0.75);
  z-index: 10;
  border-bottom: 1px solid white;

  .fileName {
    width: 26% !important;
    display: flex;
    margin-right: 0.5rem;
    padding-left: 0.2rem;
    border-radius: 15px;
    box-shadow: 0px 0px 0px 1px rgba(255, 255, 255, 0.75);
    background-color: transparent;
    z-index: 20;
    padding-right: 0.2rem;

    label {
      width: 80%;
      padding: 0.3rem;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    b {
      display: flex;
      align-items: center;
      color: black;
      background-color: red;
      cursor: pointer;
    }
  }

  .cancelButton {
    position: absolute;
    width: 30px;
    height: 20px;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 20;
    .cancel {
      position: absolute;
      top: 50%;
      left: 13%;
      transform: translate(-50%, -50%);
      width: 16px;
      height: 1.2px;
      background-color: rgb(255, 255, 255);
      border-radius: 4px;
      transition: 0.5s;

      &:nth-child(1) {
        transform: rotate(-135deg);
      }

      &:nth-child(2) {
        transform: rotate(135deg);
      }
    }
  }
}

.policy {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  color: white;

  span {
    text-align: center;
    width: 65%;

    @media (max-width: 650px) {
      width: 80%;
    }

    @media (max-width: 410px) {
      width: 90%;
    }

    a {
      text-decoration: none;
      color: #ffff;
      text-decoration: underline;
    }
  }
}

.submitButton {
  width: 100%;
  margin-top: 4rem;
  background: #d6ff63;
  color: #000000 !important;
  border-radius: 10px;
  cursor: pointer;
  transition: background 0.3s;
  text-align: center;
  cursor: pointer;

  @media (max-width: 700px) {
    margin-top: 4rem;
  }

  button {
    border: none;
    font-size: 16px;
    background-color: transparent;
    font-family: "Inter";
    font-size: 23px;
    padding: 1.5rem;
    cursor: pointer;
    color: #000000 !important;
  }
}

@supports (-webkit-touch-callout: none) {
  .submitButton button {
    color: #000000 !important;
  }
}

.submitButton:hover {
  background: #b4e600;
}
